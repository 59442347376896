<template>
  <div class="w-full max-w-md">
    <form @submit.prevent="submitForm">
      <div class="relative mb-6 md:mb-12">
        <h1
          class="mb-2 text-left text-3xl font-light tracking-tight dark:text-white sm:text-4xl"
        >
          Forgot your password?
        </h1>
        <p class="text-left text-gray-700 dark:text-gray-500">
          Just enter your email address and we'll send you a link to reset it.
        </p>
        <Alert v-if="error" type="error" class="mt-4">
          {{ errorMsg }}
        </Alert>
      </div>
      <label for="email" class="mb-1 inline-block"> Email </label>
      <input
        id="email"
        v-form-control
        v-model="email"
        type="email"
        name="email"
        autocomplete="username"
        class="text-lg md:text-base"
      />
      <div class="mt-10 flex items-center justify-between md:mt-14">
        <button
          type="submit"
          class="btn btn-purple"
          :class="{ loading: submitting }"
          :disabled="submitting"
        >
          Submit
        </button>
        <RouterLink
          to="/login"
          class="text-sm text-gray-600 hover:text-purple-500 dark:text-gray-500 dark:hover:text-purple-500"
        >
          ← Back to Log in
        </RouterLink>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from '@web/components/utilities/Alert.vue';

export default {
  components: { Alert },
  beforeRouteLeave(to, from, next) {
    this.reset();
    this.email = '';
    next();
  },
  data() {
    return {
      submitting: false,
      error: false,
      errorMsg: '',
      email: '',
    };
  },
  methods: {
    reset() {
      this.error = false;
      this.errorMsg = '';
    },
    submitForm() {
      this.submitting = true;
      this.reset();
      axios
        .post('/ajax/password/reset/email', {
          email: this.email,
        })
        .then((response) => {
          this.submitting = false;
          this.$router.push({ path: '/login', query: { reset: '1' } });
        })
        .catch((err) => {
          this.error = true;
          this.submitting = false;

          this.errorMsg = err.getErrorMessage();

          if (!err.hasErrorMessage()) {
            throw err;
          }
        });
    },
  },
};
</script>
